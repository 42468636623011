import React, { Component } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/molecules/SEO';
import Pagination from '../components/organisms/Pagination';
import NewsPreview from '../components/molecules/NewsPreview';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import styled from 'styled-components';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 8rem 0;
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

class NewsArchive extends Component {

  render() {
    const { newsHeroImage } = this.props.data.contentfulGlobalElements;

    return (
      <>
        <SEO title="News"/>
        <PreHead text="News" fluidImage={newsHeroImage.fluid}/>
        <Container>
          <Holder>
            {this.props.data.allContentfulNews.nodes.map( node => (
              <NewsPreview key={node.id} news={node}/>
            ) )}
          </Holder>
          <Pagination base="news" pageContext={this.props.pageContext}/>
        </Container>
      </>
    )
  }
}

export default NewsArchive;

export const newsArchiveQuery = graphql`
    query newsArchiveQuery($skip: Int!, $limit: Int!) {
        allContentfulNews(
            sort: {fields: [date], order: DESC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                title
                date(formatString: "DD.MM.YY")
                slug
                excerpt
                createdAt(formatString: "DD.MM.YY")
                image {
                    fluid(maxWidth: 1000) {
                        sizes
                        src
                        srcSet
                        aspectRatio
                    }
                }
            }
        }
        contentfulGlobalElements(title: {eq: "Global elements"}) {
            newsHeroImage {
                fluid(maxWidth: 3000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
        }
    }
`;
