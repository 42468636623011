import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import ImageFade from '../atoms/ImageFade';

const Holder = styled.div`
  a { 
    text-decoration: none; 
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
    position: relative;
    display: block;
    h2 {
      margin-top: 0;
    }
  }
`;

class NewsPreview extends Component {
  render() {
    const { news } = this.props;
    return (
      <Holder>
        <Link to={`/news/${news.slug}`}>
          {news.image && <ImageFade>
            <Img
              style={{
                height: 0,
                paddingBottom: '80%',
              }}
              fluid={news.image.fluid}/>
          </ImageFade>}
          <h2>{news.title}</h2>
          <small>{news.date}</small>
          <p className="small">{news.excerpt} <br/>Read More...</p>
        </Link>
      </Holder>
    )
  }
}

NewsPreview.propTypes = {
  news: PropTypes.shape( {
    title: PropTypes.string,
    date: PropTypes.string,
    slug: PropTypes.string,
    excerpt: PropTypes.string,
    image: PropTypes.object,
  } ).isRequired,
};

export default NewsPreview;
