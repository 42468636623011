import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Holder = styled.div`
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      margin: 0 1rem;
    }
  }
  p {
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
  a {
    text-decoration: none;
    position: relative;
    :after {
      content: '';
      opacity: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: ${props => props.theme.colours.green};
      position: absolute;
      top: calc(100% + 1rem);
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.25s;
    }
    &:hover:after { opacity: 1; }
    &[aria-current="page"]:after {
      opacity: 1;
      background-color: ${props => props.theme.colours.yellow};
    }
  }
`;


class Pagination extends Component {

  render() {

    const { base, pageContext } = this.props;
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? `/${base}` : `/${base}/${(currentPage - 1).toString()}`;
    const nextPage = `/${base}/${(currentPage + 1).toString()}`;

    if ( numPages === 1 ) {
      return null;
    } else {
      return (
        <Container>
          <Holder>
            {!isFirst && (
              <p>
                <Link to={prevPage} rel="prev">Previous Page</Link>
              </p>
            )}
            <ul>
              {Array.from( { length: numPages }, ( _, i ) => (
                <li key={`pagination-number${i + 1}`}>
                  <Link to={`/${base}/${i === 0 ? '' : i + 1}`}>
                    {i + 1}
                  </Link>
                </li>
              ) )}
            </ul>
            {!isLast && (
              <p>
                <Link to={nextPage} rel="next">Next Page</Link>
              </p>
            )}
          </Holder>
        </Container>
      )
    }
  }
}

Pagination.propTypes = {
  base: PropTypes.string,
  pageContext: PropTypes.object,
};

export default Pagination;
