import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import SharpImage from '../atoms/SharpImage';
import ImageFade from '../atoms/ImageFade';
import Img from 'gatsby-image';
import Video from '../atoms/Video';

const slideIn = keyframes`
  from { 
    transform: translateY(3rem); 
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Holder = styled.div`
  width: 100%;
  min-height: ${props => props.height};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  text-align: center;
  padding: 10rem 0;
  width: calc(100% - 4rem);
  max-width: 60rem;
  position: relative;
  z-index: 2;
  animation: ${slideIn} 1s ease-in-out 0.5s;
  h1 {
    font-weight: 600;
  }
`;

const ImageHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

class PreHead extends Component {
  render() {
    const { image, fluidImage, text, height, homePage } = this.props;
    const style = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
    return (
      <Holder height={height}>
        {homePage && <Video/>}
        {image && <ImageHolder><SharpImage
          hoverEffect={false}
          imgName={image}
          style={style}/></ImageHolder>}
        {fluidImage && <ImageHolder><ImageFade hoverEffect={false} positioning="absolute">
          <Img
          fluid={fluidImage}
            style={style}/></ImageFade></ImageHolder>}
        {text && <Text><h1>{text}</h1></Text>}
      </Holder>
    )
  }
}

PreHead.propTypes = {
  image: PropTypes.string,
  fluidImage: PropTypes.object,
  text: PropTypes.string,
  height: PropTypes.string,
  homePage: PropTypes.bool,
};

PreHead.defaultProps = {
  text: null,
  height: '30vh',
  homePage: false
};

export default PreHead;
