import React, { Component } from 'react';
import styled from 'styled-components';
import HeroVideo from '../../assets/hero.mp4';
import ImageFade from './ImageFade';


const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
  }
`;

class Video extends Component {
  render() {
    return (
      <Holder>
        <ImageFade hoverEffect={false} positioning="absolute">
          <video muted={true} autoPlay={true} loop={true}>
            <source src={HeroVideo} type="video/mp4"/>
          </video>
        </ImageFade>
      </Holder>
    )
  }
}

export default Video;
